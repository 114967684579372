import submitCodeHandler from '../handlers/submitCodeHandler';
import emailVerificationHandler from '../handlers/emailVerificationHandler';
import messageHandler from '../handlers/messageHandler';
import commonHandler from '../utils/commonHandler';

const submitPageHandler = (() => {
    const userEmailAddress = localStorage.getItem('user-email-address');
    const token = localStorage.getItem('token');

    const init = (): void => {
        setFooterCss();
        setEventListeners();
        setMessage();
        commonHandler.setCopyRightsYear();
        commonHandler.showPage();
    };

    const setFooterCss = () => {
        document.getElementById('footer').style.position = 'fixed';
    };

    const setEventListeners = (): void => {
        document.getElementById('submit-btn-finish').addEventListener('click', finishButtonClicked);
        document.getElementById('send-new-code').addEventListener('click', sendNewCode);
    };

    const finishButtonClicked = async () => {
        commonHandler.startLoading();
        try {
            await submit();
        } catch (err) {
            console.log(err);
        } finally {
            commonHandler.endLoading();
        }
    };

    const sendNewCode = async () => {
        commonHandler.hideErrorMessage('input-code', 'input-error-message');
        commonHandler.startLoading();
        try {
            await emailVerificationHandler.sendVerificationEmail(userEmailAddress, token);
        } catch (err) {
            console.log(err);
        } finally {
            commonHandler.endLoading();
        }
    };

    const submit = async (): Promise<void> => {
        commonHandler.hideErrorMessage('input-code', 'input-error-message');
        commonHandler.startLoading();
        try {
            const badgeConsent = localStorage.getItem('badge-checkbox') === 'true';
            const marketingConsent = localStorage.getItem('marketing-checkbox') === 'true';
            const code = getCode();

            if (!code) {
                commonHandler.showErrorMessage(
                    'input-code',
                    'input-error-message',
                    messageHandler.getMessage('inputCode')
                );
                return;
            }

            const res = await submitCodeHandler.submit(
                'submit',
                token,
                marketingConsent,
                badgeConsent,
                code
            );

            if (res.result) location.href = 'submitted.html';
            else commonHandler.showErrorMessage('input-code', 'input-error-message', res.message);
        } catch (err) {
            console.log(err);
            alert(messageHandler.getMessage('error'));
        } finally {
            commonHandler.endLoading();
        }
    };

    const getCode = () => {
        const codeElement = document.getElementById('input-code') as HTMLInputElement;
        return codeElement.value;
    };

    const setMessage = (): void => {
        document.getElementById(
            'label-message'
        ).innerHTML = `For security purposes, a verification code was sent to ${userEmailAddress}. Please check your email and provide the security code below.`;
    };

    return {
        init,
    };
})();

export default submitPageHandler;
