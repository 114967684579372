const commonHandler = (() => {
    const getURLParameter = (param: string) => {
        const sPageURL = window.location.search.substring(1);
        const params = new URLSearchParams(sPageURL);
        return params.get(param);
    };

    const startLoading = (): void => {
        const loadingElement = document.getElementById('loading') as HTMLInputElement;
        loadingElement.style.display = 'block';
    };

    const endLoading = (): void => {
        const loadingElement = document.getElementById('loading') as HTMLInputElement;
        loadingElement.style.display = 'none';
    };

    const showPage = (): void => {
        const bodyElement = document.getElementById('body') as HTMLInputElement;
        bodyElement.style.visibility = 'visible';
    };

    const showErrorMessage = (
        inputboxElementName: string,
        messageElementName: string,
        message: string
    ): void => {
        const messageElement = document.getElementById(messageElementName);
        const inputboxElement = document.getElementById(inputboxElementName);
        inputboxElement.style.borderColor = 'red';
        messageElement.innerHTML = '';
        messageElement.innerHTML += '&#9888; ' + message;
        messageElement.style.display = 'block';
    };

    const hideErrorMessage = (inputboxElementName: string, messageElementName: string): void => {
        const messageElement = document.getElementById(messageElementName);
        const inputboxElement = document.getElementById(inputboxElementName);
        messageElement.style.display = 'none';
        inputboxElement.style.borderColor = 'black';
    };

    const setCopyRightsYear = (): void => {
        const copyRightsYearElement = document.getElementById(
            'copy-rights-year'
        ) as HTMLInputElement;
        copyRightsYearElement.innerHTML = new Date().getFullYear().toString();
    };

    return {
        getURLParameter,
        startLoading,
        endLoading,
        showPage,
        showErrorMessage,
        hideErrorMessage,
        setCopyRightsYear,
    };
})();

export default commonHandler;
