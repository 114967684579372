import commonHandler from '../utils/commonHandler';
import axios from 'axios';

// Request handler for emailVerification, submit code and message.
/**
 * @param {string}  method -Axio method to do http requests, for example get/post/delete..
 * @param {boolean} path - The Apigateway path for the http request
 * @param {object} payload - User gives consent to issue badges
 * @param {string} [token  = undefined] The JwT bearer token used for authentication issued by Rustici
 * @returns The result of the operation
 */
const requestHandler = (() => {
    const makeRequest = async (
        method: string,
        path: string,
        payload: {},
        token: string
    ) => {
        let res;

        // JwT Token is mandatory for AuthN and AuthZ
        if (!token) {
            console.error("Missing JwT");
            throw new Error("Missing JwT");
        }


        try {
            res = await axios({
                method: method,
                url: `${process.env.BACKEND_HOST}${path}`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                data: payload,
            });
        } catch (err) {
            console.error(err);
            throw err;
        }
        return res.data;
    };

    const sendVerificationEmail = async (
        userMail: string,
        token: string
    ): Promise<{ message: string; result: string }> => {
        let res;
        try {
            res = await makeRequest(
                'post',
                'send-verification-email',
                {
                    userMail: `${userMail}`,
                },
                token
            );
        } catch (err) {
            console.error(err);
            throw new Error();
        }

        return res;
    };

    const submit = async (
        type: string,
        marketingConsent: boolean,
        badgeConsent: boolean,
        code: string,
        token: string
    ) => {
        let res;
        try {
            res = await makeRequest(
                'post',
                'submit-code',
                {
                    type: type,
                    marketingConsent: marketingConsent,
                    badgeConsent: badgeConsent,
                    code: code,
                },
                token
            );
        } catch (err) {
            console.error(err);
            throw new Error();
        }

        return res;
    };

    return {
        sendVerificationEmail,
        submit,
    };
})();

export default requestHandler;
