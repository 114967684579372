import emailVerificationHandler from '../handlers/emailVerificationHandler';
import submitCodeHandler from '../handlers/submitCodeHandler';
import messageHandler from '../handlers/messageHandler';
import commonHandler from '../utils/commonHandler';

const indexPageHandler = (() => {
    const init = (): void => {
        uncheckAllCheckboxes();
        setEventListeners();
        commonHandler.setCopyRightsYear();
        commonHandler.showPage();
        localStorage.setItem('token', commonHandler.getURLParameter('token'));
    };

    const uncheckAllCheckboxes = (): void => {
        const chkBadgeElement = document.getElementById('chk-badge') as HTMLInputElement;
        const chkMarketingElement = document.getElementById('chk-marketing') as HTMLInputElement;

        chkBadgeElement.checked = false;
        chkMarketingElement.checked = false;
    };

    const setEventListeners = (): void => {
        document.getElementById('index-btn-continue').addEventListener('click', continueClicked);
        document.getElementById('chk-badge').addEventListener('click', checkChecked);
        document.getElementById('chk-marketing').addEventListener('click', checkChecked);
    };

    const continueClicked = async () => {
        commonHandler.hideErrorMessage('input-email-address', 'input-error-message');
        const display = document.getElementById('email-div').style.display;
        if (display === 'block') {
            // user checked checkboxes
            await sendVerificationEmail();
        } else if (display === 'none') {
            // user didn't check checkboxes
            commonHandler.startLoading();
            try {
                await submitCodeHandler.submit('skip', localStorage.getItem('token'));
            } catch (err) {
                alert(messageHandler.getMessage('error'));
                console.log(err);
            } finally {
                commonHandler.endLoading();
            }

            location.href = 'submitted.html';
        }
    };

    const sendVerificationEmail = async (): Promise<void> => {
        const emailInputElement = document.getElementById(
            'input-email-address'
        ) as HTMLInputElement;

        if (!emailInputElement.checkValidity()) {
            commonHandler.showErrorMessage(
                'input-email-address',
                'input-error-message',
                messageHandler.getMessage('inputEmailAddressFormat')
            );
            return;
        }

        const emailAddress = getEmailAddress();
        if (!emailAddress) {
            commonHandler.showErrorMessage(
                'input-email-address',
                'input-error-message',
                messageHandler.getMessage('inputEmailAddress')
            );
            return;
        }

        commonHandler.startLoading();
        try {

            const res = await emailVerificationHandler.sendVerificationEmail(
                emailInputElement.value,
                localStorage.getItem('token')
            );
            const EmailFlags = emailVerificationHandler.getEmailFlags();
            switch (res) {
                case EmailFlags.EMAIL_FAILED_TO_SEND:
                    if (process.env.STAGE === 'dev') {
                        alert(
                            `${messageHandler.getMessage(
                                'emailFailed'
                            )}: The email address needs to be verified in Amazon SES Sandbox ahead.`
                        );
                    } else {
                        alert(messageHandler.getMessage('emailFailed'));
                    }
                    break;
                case EmailFlags.EMAIL_SENT:
                    const chkBadgeElement = document.getElementById(
                        'chk-badge'
                    ) as HTMLInputElement;
                    const chkMarketingElement = document.getElementById(
                        'chk-marketing'
                    ) as HTMLInputElement;

                    localStorage.setItem('user-email-address', emailInputElement.value);
                    localStorage.setItem('badge-checkbox', chkBadgeElement.checked.toString());
                    localStorage.setItem(
                        'marketing-checkbox',
                        chkMarketingElement.checked.toString()
                    );

                    location.href = 'submit.html';
                    break;
                default:
                    alert(messageHandler.getMessage('error'));
                    throw new Error('Unexpected Case Error!');
            }
        } catch (err) {
            alert(messageHandler.getMessage('error'));
            console.log(err);
        } finally {
            commonHandler.endLoading();
        }
    };

    const checkChecked = (): void => {
        const chkBadgeElement = document.getElementById('chk-badge') as HTMLInputElement;
        const chkMarketingElement = document.getElementById('chk-marketing') as HTMLInputElement;

        const contentHeight = document.getElementById('content').offsetHeight;

        if (chkBadgeElement.checked || chkMarketingElement.checked) {
            document.getElementById('email-div').style.display = 'block';
            const fullsizedWindowBaseline = 900;
            contentHeight > fullsizedWindowBaseline
                ? (document.getElementById('footer').style.position = 'fixed')
                : (document.getElementById('footer').style.position = 'initial');
        } else {
            document.getElementById('email-div').style.display = 'none';
            const iframeBaseline = 775;
            contentHeight > iframeBaseline
                ? (document.getElementById('footer').style.position = 'fixed')
                : (document.getElementById('footer').style.position = 'initial');
        }
    };

    const getEmailAddress = (): string => {
        const emailInputElement = document.getElementById(
            'input-email-address'
        ) as HTMLInputElement;
        const emailAddress = emailInputElement.value;

        return emailAddress;
    };

    const getMarketingConsent = () => {
        const marketingElement = document.getElementById('chk-marketing') as HTMLInputElement;
        return marketingElement.checked;
    };

    return {
        init,
        getMarketingConsent,
    };
})();

export default indexPageHandler;
