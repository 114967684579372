import requestHandler from './requestHandler';



// Submit the code verification and information captured from the form
/**
 * @param {string}  type - Type of operatoin between submit/skip
 * @param {boolean} marketingConsent - User gives consent for marketing purposes
 * @param {boolean} badgeConsent - User gives consent to issue badges
 * @param {string} [code] - An optional 6-digit code used for email verification
 * @param {string} [token]  The JwT bearer token used for authentication issued by Rustici
 * @returns The result of the request
 */

const submitCodeHandler = (() => {
    const submit = async (
        type: string,
        token: string,
        marketingConsent?: boolean,
        badgeConsent?: boolean,
        code?: string
    ) => {
        return await requestHandler.submit(type, marketingConsent, badgeConsent, code, token);
    };

    return {
        submit,
    };
})();

export default submitCodeHandler;
