import commonHandler from '../utils/commonHandler';

const submittedPageHandler = (() => {
    const init = (): void => {
        setFooterCss();
        commonHandler.setCopyRightsYear();
        commonHandler.showPage();
        sendMsgToParent();
    };

    const setFooterCss = () => {
        document.getElementById('footer').style.position = 'fixed';
    };

    const sendMsgToParent = () => {
        window.parent.postMessage(
            {
                message: '',
            },
            process.env.CONTENT_CONTROLLER_INSTANCE
        );
    };

    return {
        init,
    };
})();

export default submittedPageHandler;
