import './styles/main.scss';
import indexPageHandler from './scripts/pageHandlers/indexPageHandler';
import submitPageHandler from './scripts/pageHandlers/submitPageHandler';
import submittedPageHandler from './scripts/pageHandlers/submittedPageHandler';
import rusticiPageHandler from './scripts/pageHandlers/rusticiPageHandler';
import logo from './assets/aws_logo.png';
import badge from './assets/aws_education_programs_badge.png';
import success from './assets/success.png';

enum Pages {
    index = '/',
    submit = '/submit.html',
    submitted = '/submitted.html',
    rustici = '/rustici.html',
}

const logoImg = document.getElementById('logo-img') as HTMLImageElement;
if (logoImg !== null) logoImg.src = logo;
const badgeImg = document.getElementById('badge-img') as HTMLImageElement;
if (badgeImg !== null) badgeImg.src = badge;
const indexSuccessImg = document.getElementById('index-success-img') as HTMLImageElement;
if (indexSuccessImg !== null) indexSuccessImg.src = success;

const path = window.location.pathname;
switch (path) {
    case Pages.index:
        indexPageHandler.init();
        break;
    case Pages.submit:
        submitPageHandler.init();
        break;
    case Pages.submitted:
        submittedPageHandler.init();
        break;
    case Pages.rustici:
        rusticiPageHandler.init();
        break;
    default:
}

export default indexPageHandler;
