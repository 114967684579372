export const en: { [key: string]: any } = {
    error: 'Something went wrong. Please contact the administrator.',
    inputEmailAddressFormat: 'Please enter a valid email.',
    inputEmailAddress: 'Please enter a valid email or uncheck the selection(s) above.',
    inputCode: 'To continue, enter the code that was sent to your email.',
    alreadyVerified: 'The user is already verified.',
    emailSent: 'Verification email has been sent. Please check the inbox.',
    emailFail: 'Failed to send email.',
    checkTc: 'Please check Terms & Conditions checkbox.',
    checkEmail: 'Please check marketing emails checkbox.',
};
