const rusticiPageHandler = (() => {
    const init = () => {
        const frontEndHost = process.env.FRONTEND_HOST;
        const token = process.env.TOKEN;

        // Laptop size in Content Control: 1190px, 560px
        // Monitor size in Content Control: 1600px, 780px

        window.addEventListener('message', receiveMsgFromChild);
        document.getElementById(
            'iframe-div'
        ).innerHTML = `<iframe id="iframe-lead-in-page" style="width: 1600px; height: 780px;" sandbox="allow-scripts" width="800" height="800" src="${frontEndHost}?token=${token}"/>`;
    };

    const receiveMsgFromChild = (e: any) => {
        console.log('closed');
    };
    return {
        init: init,
    };
})();

export default rusticiPageHandler;
